import { PaywallsService } from "../../_paywalls/private/services/paywalls.service";
import { Touchpoint } from "../../api/public/subscription-api";
import { AuthService, Container, Service, history } from "../../symphony";

@Service()
export class GetProService {
	private authService = Container.take('global', AuthService);
	private paywallService = Container.take('paywalls', PaywallsService);

	public openPaywall = () => {
		if (this.authService.isLoggedIn()) {
			this.paywallService.openPaywall({
				touchpoint: Touchpoint.comms,
				props: {}
			}, {
				onClose: () => {
					history.push(`/learn`);
				}
			});
		} else {
			history.push(
				`/users/login?returnUrl=${encodeURIComponent(window.location.pathname)}`,
			);
		}
	};
}
