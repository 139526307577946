import { PaywallArguments, PaywallCallbacks, PaywallClickTypes, PaywallErroredData, PaywallNavigation, PaywallNavigationPage, PaywallOfferModalId } from './../paywalls.constants';
import { Service, Container, history, UserTrackService, ModalService, StorageService } from "../../../symphony";
import { PaywallState } from "../paywalls.constants";
import { PaywallsStore } from "../paywalls.store";
import { PaywallData, SubscriptionApi } from '../../../api/public/subscription-api';
import { BehaviorSubject, Observable, filter, take, tap, switchMap, of, catchError, interval, map } from 'rxjs';
import { PaywallsTrackingService } from './paywalls.tracking.service';
import { Action } from 'history';

import SHA256 from 'crypto-js/sha256';
import encHex from 'crypto-js/enc-hex';

@Service()
export class PaywallsService {
	private store = Container.take('paywalls', PaywallsStore);
	private api = Container.take('paywalls', SubscriptionApi);
	private tracking = Container.take('paywalls', PaywallsTrackingService);
	private userTrackService = Container.take('global', UserTrackService);
	private modalService = Container.take('global', ModalService);
	private storageService = Container.take('global', StorageService);
	private isFetching = new BehaviorSubject<boolean>(false);
	private fetchObservable: Observable<any> | null = null;
	private onClose: (method?: 'back' | 'x') => void = null;
	private onCountdownEnd: () => void = null;

	constructor() {
		this.userTrackService.userTrack.subscribe(track => {
			this.store.paywallsMap.next({});
		});
	}

	private sortObject = (obj: any): any => {
		if (Array.isArray(obj)) {
			return obj.map(this.sortObject);
		} else if (obj && typeof obj === 'object') {
			return Object.keys(obj)
				.sort()
				.reduce((result: any, key: string) => {
					result[key] = this.sortObject(obj[key]);
					return result;
				}, {});
		}
		return obj;
	};

	private generateHash = (...args: any[]): string => {
		const normalizedArgs = args.map(this.sortObject);
		const serializedArgs = JSON.stringify(normalizedArgs);
		const hash = SHA256(serializedArgs);
		return hash.toString(encHex);
	};

	private disableScroll = () => {
		document.body.style.overflow = 'hidden';
	};

	private enableScroll = () => {
		document.body.style.overflow = 'auto';
	};

	private setPaywallData = (args: PaywallArguments, paywallData: PaywallData) => {
		const hash = this.generateHash(args);
		const newMap = { ...this.store.paywallsMap.value };
		newMap[hash] = paywallData;
		this.store.paywallsMap.next(newMap);
	};

	private removeErroredDataFromMap = () => {
		const paywallsMap = { ...this.store.paywallsMap.value };
		const newMap: { [hash: string]: PaywallData; } = {};
		Object.entries(paywallsMap).forEach((([key, value]) => {
			if (value instanceof PaywallErroredData) return;
			newMap[key] = value;
		}));
		this.store.paywallsMap.next(newMap);
	};

	private retrievePaywallData = (args: PaywallArguments): PaywallData => {
		const hash = this.generateHash(args);
		return this.store.paywallsMap.value[hash] || null;
	};

	private fetchPaywall = (args: PaywallArguments) => {
		return this.api.getPaywall(args.touchpoint, args.props)
			.pipe(
				tap(data => {
					this.setPaywallData(args, data);
					this.isFetching.next(false);
				}),
				catchError(_ => {
					this.setPaywallData(args, new PaywallErroredData() as PaywallData);
					this.isFetching.next(false);
					return of(null);
				})
			);
	};

	private showPaywall = () => {
		this.store.paywallState.next(PaywallState.open);
		this.disableScroll();
	};

	private setNavigation = (page: PaywallNavigationPage, step: string, skipPush: boolean = false) => {
		if (!skipPush) history.push(`${window.location.pathname}${window.location.search}`, { paywall: { page, step } });
		this.store.paywallNavigation.next({ page, step });
		this.tracking.trackImpression();
	};

	private cleanRemainingNavigation = () => {
		if (history.location.state && history.location.state['paywall']) {
			history.replace(`${window.location.pathname}${window.location.search}`);
		}
	};

	public initPaywallInfo = () => {
		this.getPaymentProducts();
		this.cleanRemainingNavigation();
		history.listen((event) => {
			if (event.action === Action.Pop) {
				if (event.location.state && event.location.state['paywall']) {
					if (this.store.paywallState.value === PaywallState.closed) {
						history.replace(null);
					} else {
						const state = event.location.state['paywall'] as PaywallNavigation;
						this.setNavigation(state.page, state.step, true);
					}
				} else {
					this.closePaywall(false, 'back');
				}
			}
		});
	};

	public prefetchPaywall = (args: PaywallArguments) => {
		const paywallData = this.retrievePaywallData(args);
		if (paywallData) {
			return;
		}
		if (!this.isFetching.getValue()) {
			this.isFetching.next(true);
			this.fetchObservable = this.fetchPaywall(args).pipe(take(1));
			this.fetchObservable.subscribe();
		}
	};

	public openPaywall = (args: PaywallArguments, callbacks?: PaywallCallbacks) => {
		this.store.currentTouchpoint.next(args.touchpoint);
		this.store.paywallStyles.next(args?.styles);

		const paywallData = this.retrievePaywallData(args);
		if (paywallData) {
			this.store.paywallData.next(paywallData);
			this.setNavigation(PaywallNavigationPage.paywall, '1');
		} else {
			this.isFetching.pipe(
				filter(isFetching => !isFetching),
				switchMap(() => {
					if (this.retrievePaywallData(args)) {
						return of(this.retrievePaywallData(args));
					} else {
						this.isFetching.next(true);
						this.fetchObservable = this.fetchPaywall(args).pipe(take(1));
						return this.fetchObservable;
					}
				}),
				take(1)
			).subscribe(data => {
				this.store.paywallData.next(data);
				this.setNavigation(PaywallNavigationPage.paywall, '1');
			});
		}
		this.onClose = callbacks?.onClose;
		this.onCountdownEnd = callbacks?.onCountdownEnd;
		this.showPaywall();
	};

	public closePaywall = (shouldTrack: boolean = true, method: 'back' | 'x' = 'x') => {
		this.onClose && this.onClose(method);
		shouldTrack && this.tracking.trackClick(PaywallClickTypes.close);
		this.store.paywallState.next(PaywallState.closed);
		if (this.store.paywallData.value instanceof PaywallErroredData) {
			this.removeErroredDataFromMap();
		};
		this.store.paywallData.next(null);
		this.store.currentTouchpoint.next(null);
		this.store.paywallNavigation.next(null);
		this.enableScroll();
		this.store.selectedProductKey.next(null);
		this.cleanRemainingNavigation();
	};

	public getPaymentProducts = () => {
		this.api.getPaymentProducts()
			.pipe(tap(data => {
				this.store.paymentProducts.next(data);
			}))
			.subscribe();
	};

	public selectProduct = (props: {
		productId: string;
		offerId?: string;
		customCTAText?: string;
	}) => {
		const { productId, offerId, customCTAText } = props;
		this.store.selectedProductKey.next(productId);
		this.store.offerProductKey.next(offerId || null);
		this.store.customCTAText.next(customCTAText || null);
	};

	public trackPaywallClick = (clickType: PaywallClickTypes, value?: string) => {
		this.tracking.trackClick(clickType, value || null);
	};

	public navigateToCheckout = (sectionName?: string) => {
		if (this.store.selectedProductKey.value) {
			this.tracking.trackClick(PaywallClickTypes.cta, sectionName || null);
			if (this.store.offerProductKey.value) {
				this.modalService.open(PaywallOfferModalId);
			} else if (this.store.selectedProductKey.value === 'free') {
				this.closePaywall(false);
			} else {
				this.setNavigation(PaywallNavigationPage.checkout, 'default');
			}
		}
	};

	public switchPage = (step: string) => {
		this.tracking.trackClick(PaywallClickTypes.cta);
		this.setNavigation(PaywallNavigationPage.paywall, step);
		this.store.customCTAText.next(null);
	};

	public navigateToCongrats = () => {
		this.setNavigation(PaywallNavigationPage.congrats, 'default', true);
		this.cleanRemainingNavigation();
		this.store.customCTAText.next(null);
	};

	public countdownSetup = (seconds: number) => {
		const ongoingCountdown = this.storageService.load('paywallOfferCountdown', 'local');
		const startFrom = +ongoingCountdown || seconds;
		return interval(1000).pipe(
			map(val => startFrom - val),
			take(startFrom + 1),
			tap((value) => {
				if (value === 0) {
					this.onCountdownEnd && this.onCountdownEnd();
				}
				this.store.countdown.next(value);
				this.storageService.save('paywallOfferCountdown', value.toString(), 'local');
			})
		);
	};

	public trackSectionView = (section: string, sectionOrder: number) => {
		this.tracking.trackSectionView(section, sectionOrder);
	};
}
