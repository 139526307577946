import React, { FC } from "react";
import s from "./LongPaywallHeadings.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { i18n } from "../../../../../../symphony";

type Props = {
	data: {
		heading: string;
		subHeading: string;
	};
};

export const LongPaywallHeadings: FC<Props> = ({ data }) => {
	useStyles(s);
	return <div className="headings">
		<h1>{i18n.t(data.heading)}</h1>
		<h2>{i18n.t(data.subHeading)}</h2>
	</div>;
};
