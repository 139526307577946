import { BehaviorSubject } from "rxjs";
import { Service } from "../../symphony";
import { PaymentProduct } from "../../api/public/subscription-api";

@Service()
export class CheckoutStore {
	public cardInitialized = new BehaviorSubject<boolean>(false);
	public paypalInitialized = new BehaviorSubject<boolean>(false);
	public applePayInitialized = new BehaviorSubject<boolean>(false);
	public supportsApplePay = new BehaviorSubject<boolean>(false);
	public transactionInProcess = new BehaviorSubject<boolean>(false);
	public zipCodeRequired = new BehaviorSubject<boolean>(true);
	public cardError = new BehaviorSubject<string>(null);
	public selectedProductKey = new BehaviorSubject<string>(null);
	public selectedPaymentProduct = new BehaviorSubject<PaymentProduct>(null);
}
