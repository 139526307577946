import React, { FC } from "react";
import s from "./LongPaywallPage.scss";
import useStyles from 'isomorphic-style-loader/useStyles';
import { LongPaywallHeadings } from "./components/LongPaywallHeadings/LongPaywallHeadings";
import { LongPaywallAwards } from "./components/LongPaywallAwards/LongPaywallAwards";
import { LongPaywallComparison } from "./components/LongPaywallComparison/LongPaywallComparison";
import { LongPaywallReviews } from "./components/LongPaywallReviews/LongPaywallReviews";
import { LongPaywallFAQ } from "./components/LongPaywallFAQ/LongPaywallFAQ";
import { LongPaywallPartnerships } from "./components/LongPaywallPartnerships/LongPaywallPartnerships";
import { PaywallComponentType, PaywallPage } from "../../../../api/public/subscription-api";
import { LongPaywallProducts } from "./components/LongPaywallProducts/LongPaywallProducts";

type Props = {
	page: PaywallPage;
};

export const LongPaywallPage: FC<Props> = ({
	page
}) => {
	useStyles(s);

	return <div className="long-paywall">
		{page.components.map((c, i) => {
			switch (c.type) {
				case PaywallComponentType.lpHeadings:
					return <LongPaywallHeadings
						key={`${c.type}+${i}`}
						data={c.data}
					/>;
				case PaywallComponentType.lpProducts:
					return <LongPaywallProducts
						key={`${c.type}+${i}`}
					/>;
				case PaywallComponentType.lpAwards:
					return <LongPaywallAwards
						key={`${c.type}+${i}`}
						data={c.data}
					/>;
				case PaywallComponentType.lpComparison:
					return <LongPaywallComparison
						key={`${c.type}+${i}`}
						data={c.data}
					/>;
				case PaywallComponentType.lpReviews:
					return <LongPaywallReviews
						key={`${c.type}+${i}`}
					/>;
				case PaywallComponentType.lpFAQ:
					return <LongPaywallFAQ
						key={`${c.type}+${i}`}
					/>;
				case PaywallComponentType.lpPartnership:
					return <LongPaywallPartnerships
						key={`${c.type}+${i}`}
					/>;
				default:
					return null;
			}
		})}
	</div>;
};