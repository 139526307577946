import React, { FC } from "react";
import { Container, i18n } from "../../../../symphony";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallPageButton.scss';
import { PaywallButton, PaywallButtonAction } from "../../../../api/public/subscription-api";
import { PaywallsService } from "../../services/paywalls.service";
import { CheckoutService } from "../../services/checkout.service";
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../paywalls.store";

type PaywallButtonProps = PaywallButton & {
	order: number;
	disabled?: boolean;
	loading?: boolean;
	loadingText?: string;
};

export const PaywallPageButton: FC<PaywallButtonProps> = ({
	text, type, action, order, disabled, loading, loadingText
}) => {
	useStyles(s);
	const { customCTAText } = useContainerData('paywalls', PaywallsStore, [
		'customCTAText'
	]);
	const { switchPage, navigateToCheckout, closePaywall } = Container.take('paywalls', PaywallsService);
	const { submitCardPayment } = Container.take('paywalls', CheckoutService);

	const onClick = () => {
		switch (action) {
			case PaywallButtonAction.next:
				switchPage((order + 1).toString());
				break;
			case PaywallButtonAction.checkout:
				navigateToCheckout();
				break;
			case PaywallButtonAction.subscribe:
				submitCardPayment();
				break;
			case PaywallButtonAction.close:
				closePaywall();
				break;
		}
	};

	return <button
		onClick={onClick}
		disabled={disabled}
		className={`paywall-button ${type} ${loading ? 'loading' : ''}`}
	>
		{i18n.t(loading ? loadingText || text : customCTAText || text)}
	</button>;
};
