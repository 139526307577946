import { PaywallComponentType, PaywallPage, PaywallPageTypes, Touchpoint } from "../../api/public/subscription-api";

export enum PaywallState {
	open = 'open',
	closed = 'closed'
}

export type PaywallArguments = {
	touchpoint: Touchpoint;
	props: { [key: string]: string; };
	styles?: PaywallExternalStyles;
};

export type PaywallCallbacks = {
	onClose?: (method?: 'back' | 'x') => void;
	onSubscribe?: () => void;
	onCountdownEnd?: () => void;
};

export enum PaywallNavigationPage {
	paywall = 'paywall',
	checkout = 'checkout',
	congrats = 'congrats'
}

export type PaywallNavigation = {
	page: PaywallNavigationPage;
	step: string;
};

export type PaywallExternalStyles = {
	container?: React.CSSProperties;
	header?: React.CSSProperties;
	loader?: React.CSSProperties;
};

export const CardFields = ['number', 'cvv', 'expirationDate', 'postalCode'];

export class PaywallErroredData { }

export const PaywallOfferModalId = 'PaywallOfferModalId';

export enum PaywallClickTypes {
	close = 'CLOSE',
	cta = 'CTA',
	freeBlock = 'FREE_BLOCK',
	monthlyBlock = 'MONTHLY_BLOCK',
	annualBlock = 'ANNUAL_BLOCK',
	quarterlyBlock = 'QUARTERLY_BLOCK',
	faqItem = 'FAQ_ITEM'
}

export const MOCK_LONG_PAYWALL: PaywallPage = {
	order: 1,
	type: PaywallPageTypes.long,
	title: null,
	subtitle: null,
	components: [
		{
			type: PaywallComponentType.lpHeadings,
			data: {
				heading: 'web-paywall.long.heading',
				subHeading: 'web-paywall.long.sub-heading'
			},
			style: {}
		},
		{
			type: PaywallComponentType.lpAwards,
			data: {
				heading: 'web-paywall.long.awards.heading'
			},
			style: {}
		},
		{
			type: PaywallComponentType.lpComparison,
			data: {
				heading: 'web-paywall.long.comparison.heading',
				plans: [
					{
						name: 'free',
						productId: null,
						cta: null
					},
					{
						name: 'pro',
						productId: '1',
						cta: 'Try PRO for free'
					},
					{
						name: 'max',
						productId: '2',
						cta: 'Get MAX now'
					}
				],
				features: {
					default: {
						"web-paywall.long.features.1": ['free', 'pro', 'max'],
						"web-paywall.long.features.2": ['pro', 'max'],
						"web-paywall.long.features.3": ['pro', 'max'],
						"web-paywall.long.features.4": ['pro', 'max'],
						"web-paywall.long.features.5": ['pro', 'max'],
						"web-paywall.long.features.6": ['pro', 'max'],
					},
					ai: {
						"web-paywall.long.features.7": ['max'],
						"web-paywall.long.features.8": ['max'],
						"web-paywall.long.features.9": ['max'],
						"web-paywall.long.features.10": ['max'],
						"web-paywall.long.features.11": ['max'],
						"web-paywall.long.features.12": ['max'],
					}
				}
			},
			style: {}
		}
	],
	footerButtons: [],
	headerVersion: 1,
	headerComponents: [],
	styles: {}
};
