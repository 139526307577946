import React, { FC, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallContainer.scss';
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../paywalls.store";
import { PaywallErroredData, PaywallNavigationPage, PaywallOfferModalId, PaywallState } from "../../paywalls.constants";
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";
import {
	useIcons,
	sololearnMono,
	stars,
	appleAward,
	starPro,
	infiniteHearts,
	noAds,
	practiceWithAI,
	prioritySupport,
	paywallTimeline1,
	paywallTimeline2,
	paywallTimeline3,
	correctRound,
	offerHeader,
	plusGradient,
	statsGradient,
	magicHand,
	crossPlatform,
	sparkleSmallStars,
	thunderFilled,
	checkmarkFilled,
	boostEfficiency,
	unleashCreativity,
	understandData,
	stayCompetitive,
	check,
	simpleStar
} from "@sololearnorg/icons";
import { CheckoutWrapper } from "../checkout/CheckoutWrapper/CheckoutWrapper";
import { SlModal } from "../../../../shared/public/SlModal/SlModal";
import { PaywallOffer } from "../PaywallOffer/PaywallOffer";
import { PageWrapper } from "../PageWrapper/PageWrapper";
import { PaywallHeader } from "../ui/PaywallHeader/PaywallHeader";
import { Congrats } from "../Congrats/Congrats";
import { PaywallPageDrawer } from "../PaywallPageDrawer/PaywallPageDrawer";

export const PaywallContainer: FC = () => {
	useStyles(s);
	useIcons([
		sololearnMono,
		stars,
		appleAward,
		starPro,
		infiniteHearts,
		noAds,
		practiceWithAI,
		prioritySupport,
		paywallTimeline1,
		paywallTimeline2,
		paywallTimeline3,
		correctRound,
		offerHeader,
		plusGradient,
		statsGradient,
		magicHand,
		crossPlatform,
		sparkleSmallStars,
		thunderFilled,
		checkmarkFilled,
		boostEfficiency,
		unleashCreativity,
		understandData,
		stayCompetitive,
		check,
		simpleStar
	]);
	const {
		paywallState,
		paywallData,
		paywallNavigation,
		paywallStyles
	} = useContainerData('paywalls', PaywallsStore, [
		'paywallState',
		'paywallData',
		'paywallNavigation',
		'paywallStyles'
	]);
	const { initPaywallInfo } = Container.take('paywalls', PaywallsService);

	useEffect(() => {
		initPaywallInfo();
	}, []);

	const container = <div className="paywalls-container" style={paywallStyles?.container || {}}>
		<div className="paywalls-inner">
			<div className="paywalls-header-container" style={paywallStyles?.header || {}}>
				<PaywallHeader version={1} />
			</div>
			<div className="paywalls-body">
				{!paywallData && <div className="paywalls-loader">
					<PageWrapper
						styles={{
							body: {
								minHeight: '0px',
								flex: 0,
								padding: 0
							},
							wrapper: paywallStyles?.loader || {}
						}}
						title=""
						headerComponents={[]}
						headerVersion={1}
					>{null}</PageWrapper>
				</div>}
				{paywallData && <>
					{(paywallData instanceof PaywallErroredData)
						? <p style={{ color: 'white' }}>
							Oops. Sorry, something went wrong. Please try again.
						</p>
						: <>
							{paywallNavigation?.page === PaywallNavigationPage.paywall && <PaywallPageDrawer />}
							{paywallNavigation?.page === PaywallNavigationPage.checkout && <CheckoutWrapper />}
							{paywallNavigation?.page === PaywallNavigationPage.congrats && <Congrats />}
						</>}
				</>}
			</div>
		</div>
		<SlModal
			id={PaywallOfferModalId}
			cssClass="paywalls-offer-modal"
			keepOnOverlay={true}
		><PaywallOffer /></SlModal>
	</div >;

	return paywallState === PaywallState.open ? container : null;
};