import React, { FC } from "react";
import s from "./LongPaywallAwards.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { i18n } from "../../../../../../symphony";
import { AppleAwardForGenerativeAI, AppStoreEditorsChoice, FacebookAppOfTheYear, GooglePlayEditorsChoice, HolonIQAward } from "../../../ui/icons";
type Props = {
	data: {
		heading: string;
	};
};

export const LongPaywallAwards: FC<Props> = ({ data }) => {
	useStyles(s);
	return <div className="awards">
		<h3>{i18n.t(data.heading)}</h3>
		<section>
			<AppleAwardForGenerativeAI />
			<GooglePlayEditorsChoice />
			<AppStoreEditorsChoice />
			<FacebookAppOfTheYear />
			<HolonIQAward />
		</section>
	</div>;
};