import React from 'react';
import { Route, Routes } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import { RouteGuard } from './Guard/RouteGuard';
import { authGuard } from './Guard/AuthGuard';
import { mobileGuard } from './Guard/MobileGuard';
import { profileGuard } from './Guard/ProfileGuard';
import {
	Home,
	User,
	Error,
	NotFound,
	Playground,
	Profile,
	NotAllowedFeature,
	PrivacyRequest,
	FAQ,
	TermsOfUse,
	CopyrightPolicy,
	ContentCreationGuidlines,
	TermsOfUseForMobile,
	Contact,
	PrivacyPolicy,
	PrivacyPolicyForMobile,
	Career,
	ConnectAccount,
	TermsOfUseForModal,
	PrivacyPolicyForModal,
	Subscriptions,
	LeaderboardLeague,
	DeleteAccountCompletion,
	LearnEngine,
	CertificateShare,
	BugBounty,
	Discuss,
	LanguageLandingPage,
	MobilePage,
	NotifiedPage,
	CodeBits,
	AILandingPage,
	DynamicOnboarding,
	GroupLeaderboard,
	Teams,
	AILiteracy,
	WTA,
} from './shared/loadable-components';
import { ModalsWrapper } from './Modals/ModalsWrapper';
import { SolModalsWrapper } from './Modals/SolModalsWrapper';
import { NavigationBar } from './MicroFrontends/NavigationBar/NavigationBar';
import { useTracking } from './utils/useTracking';
import { useScrollToTopOnPathChange } from './utils/useScrollToTopOnPathChange';
import { usePreviousPath } from './utils/usePreviousPath';
import { usePublicTokenCaptchaHandler } from './utils/usePublicTokenCaptchaHandler';
import { RefundPolicy } from './Pages/RefundPolicy/RefundPolicy';
import { MentorProgram } from './Pages/MentorProgram/MentorProgram';
import { useLocalization } from './utils/useLocalization';
import Redirect from './components/Redirect/Redirect';
import { useUserInit } from './utils/useUserInit';
import { initGlobalRegistrations } from './utils/initGlobalRegistrations';
import { useFBInit } from './utils/useFBInit';
import { SlHelmet } from '../features/shared/public/SlHelmet/SlHelmet';

import styles from './App.scss';
import { useInitCookies } from './utils/useInitCookies';
import { SlQuizTest } from '../features/staticPages/public/SlQuizTest/SlQuizTest';
import { SlPaywall } from '../features/_paywalls/public/SlPaywall';
import { WebToApp } from '../features/_webtoapp/public/WebToApp';
import { GetPro } from '../features/_getpro/public/GetPro';

interface IAppProps {
	activeLanguage?: string;
	cookies?: Record<string, string>;
}

const App: React.FC<IAppProps> = ({
	activeLanguage,
	cookies,
}) => {
	useInitCookies(cookies);
	useLocalization(activeLanguage);
	useScrollToTopOnPathChange();
	useTracking();
	initGlobalRegistrations();
	useFBInit();
	usePublicTokenCaptchaHandler();
	usePreviousPath();
	const { modalsData } = useUserInit();

	return (
		<>
			<SlHelmet />
			{/* <AppsFlyerTopBanner
				isMobileSSR={isMobileSSR}
				isAppsFlyerTopBannerHasBeenClosedSSR={
					isAppsFlyerTopBannerHasBeenClosedSSR
				}
			/> */}
			<NavigationBar />
			<div>
				<Routes>
					<Route path="/:language">
						<Route index element={<Home />} />
						<Route
							path="notallowed/*"
							element={<NotAllowedFeature />}
						/>
						<Route
							path="subscriptions"
							element={<Subscriptions />}
						/>
						<Route
							path="privacy-request"
							element={<PrivacyRequest />}
						/>
						<Route
							path="connect-account"
							element={<ConnectAccount />}
						/>
						<Route path="faq" element={<FAQ />} />
						<Route path="terms-of-use" element={<TermsOfUse />} />
						<Route
							path="copyright-policy"
							element={<CopyrightPolicy />}
						/>
						<Route
							path="learn-with-ai"
							element={<AILandingPage />}
						/>
						<Route
							path="quiz/:quizId"
							element={<SlQuizTest />}
						/>
						<Route
							path="content-creation-guidelines"
							element={<ContentCreationGuidlines />}
						/>
						<Route
							path="termsOfUse"
							element={<TermsOfUseForMobile />}
						/>
						<Route
							path="terms_conditions"
							element={<TermsOfUseForModal />}
						/>
						<Route path="contact" element={<Contact />} />
						<Route path="career" element={<Career />} />
						<Route
							path="privacy-policy"
							element={<PrivacyPolicy />}
						/>
						<Route
							path="refund-policy"
							element={<RefundPolicy />}
						/>
						<Route
							path="sololearn-mentors"
							element={<MentorProgram />}
						/>
						<Route
							path="privacyPolicy"
							element={<PrivacyPolicyForMobile />}
						/>
						<Route
							path="privacy_policy"
							element={<PrivacyPolicyForModal />}
						/>
						<Route
							path="account-delete-completion"
							element={<DeleteAccountCompletion />}
						/>
						<Route
							path="certificates/:hash"
							element={<CertificateShare />}
						/>
						<Route
							path="learn/languages/:languageAlias"
							element={<LanguageLandingPage />}
						/>
						<Route
							path="learning"
							element={<Redirect to="/learn" />}
						/>
						<Route
							path="get-pro"
							element={<GetPro />}
						/>
						<Route path="teams" element={<Teams />} />
						<Route path="learn-how-to-use-ai/*" element={<AILiteracy />} />
						<Route path="Discuss/*" element={<Discuss />} />
						<Route path="codes/*" element={<CodeBits />} />
						<Route path="bug-bounty" element={<BugBounty />} />
						<Route path="mobile" element={<MobilePage />} />
						<Route path="notified-en" element={<NotifiedPage />} />
						<Route path="notified-ru" element={<NotifiedPage />} />
						<Route path="notified-es" element={<NotifiedPage />} />
						<Route path="learn/*" element={<LearnEngine />} />
						<Route path="error/*" element={<Error />} />
						<Route path="pagenotfound/*" element={<NotFound />} />
						<Route
							path="onboarding/*"
							element={<DynamicOnboarding />}
						/>
						<Route path="payments/*"
							element={
								<Redirect to="/get-pro" />
							}
						/>
						<Route
							path="compiler-playground/*"
							element={(
								<RouteGuard
									component={<Playground />}
									guards={[mobileGuard()]}
								/>
							)}
						/>

						<Route
							path="profile/:profileId"
							element={<Profile />}
						/>

						<Route
							path="profile"
							element={(
								<RouteGuard
									component={<Profile />}
									guards={[profileGuard()]}
								/>
							)}
						/>

						<Route
							path="users/*"
							element={(
								<RouteGuard
									component={<User />}
									guards={[
										authGuard({
											guardType: 'onlyNotAuthorized',
											navigationType: 'internal',
											redirectUrl: '/profile',
										}),
									]}
								/>
							)}
						/>

						<Route
							path="leaderboard-league"
							element={(
								<RouteGuard
									component={<LeaderboardLeague />}
									guards={[
										authGuard({
											guardType: 'onlyAuthorized',
											navigationType: 'internal',
											redirectUrl: '/learn',
										}),
									]}
								/>
							)}
						/>

						<Route
							path="group-leaderboard"
							element={<GroupLeaderboard />}
						/>
						<Route
							path="glovo-leaderboard"
							element={
								<Redirect to="/group-leaderboard?group=glovo" />
							}
						/>
						<Route
							path="wta"
							element={<WTA />}
						/>
						<Route
							path="*"
							element={<Redirect to="/pagenotfound" />}
						/>
					</Route>
				</Routes>
			</div>
			<ModalsWrapper modalsData={modalsData} />
			<SolModalsWrapper />
			<SlPaywall />
		</>
	);
};

export default withStyles(styles)(App);
