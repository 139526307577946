import React, { FC, ReactElement, useEffect, useState } from "react";
import s from "./LongPaywallComparison.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { i18n } from "../../../../../../symphony";

type Props = {
	data: {
		heading: string;
		plans: {
			name: 'free' | 'pro' | 'max';
			productId: string;
			cta: string;
		}[];
		features: {
			default: {
				[featureName: string]: ('free' | 'pro' | 'max')[];
			};
			ai: {
				[featureName: string]: ('free' | 'pro' | 'max')[];
			};
		};
	};
};

type Row = {
	type: 'planNames' | 'buttons' | 'feature';
	cells: ReactElement[];
	style?: React.CSSProperties;
};

export const LongPaywallComparison: FC<Props> = ({
	data
}) => {
	useStyles(s);
	const [rows, setRows] = useState<Row[]>([]);

	useEffect(() => {
		const bigCell = (children: React.ReactNode) =>
			<div className="comparison-value big">{children}</div>;

		const smallCell = (children: React.ReactNode) =>
			<div className="comparison-value small">{children}</div>;

		const getPlanNamesRow = () => ({
			type: 'planNames',
			cells: [
				bigCell(null),
				...data.plans.map(p => smallCell(p.name))
			],
			style: { border: 'none' }
		});

		const getButtonsRow = () => ({
			type: 'buttons',
			cells: [
				bigCell(null),
				...data.plans.map(p => smallCell(p.productId ? <button>{p.cta}</button> : null))
			],
			style: {
				padding: 0,
				border: 'none'
			}
		});

		const getDefaultFeatures = () => (
			Object.keys(data.features.default).map((key) => {
				return {
					type: 'feature',
					cells: [
						bigCell(i18n.t(key)),
						<div className="comparison-value big">{ }</div>,
						<div className="comparison-value small">{data.features.default[key].includes('free') ? 'V' : '-'}</div>,
						<div className="comparison-value small">{data.features.default[key].includes('pro') ? 'V' : '-'}</div>,
						<div className="comparison-value small">{data.features.default[key].includes('max') ? 'V' : '-'}</div>,
					],
					style: {}
				};
			}));

		const _rows: Row[] = [
			getPlanNamesRow() as Row,
			getButtonsRow() as Row,
			...getDefaultFeatures() as Row[],
			getButtonsRow() as Row
		];


		Object.keys(data.features.ai).forEach((key, i) => {
			_rows.push({
				type: 'feature',
				cells: [
					<div className="comparison-value big">{i18n.t(key)}</div>,
					<div className="comparison-value small">{data.features.ai[key].includes('free') ? 'V' : '-'}</div>,
					<div className="comparison-value small">{data.features.ai[key].includes('pro') ? 'V' : '-'}</div>,
					<div className="comparison-value small">{data.features.ai[key].includes('max') ? 'V' : '-'}</div>,
				],
				style: i === Object.keys(data.features.ai).length - 1 ? { border: 'none' } : {}
			});
		});
		setRows(_rows);
	}, [data]);

	return <div className="comparison">
		<h3>{i18n.t(data.heading)}</h3>
		<section>
			{rows.map((row, i) => (<div key={row.type + i} className="comparison-row" style={row.style || {}}>
				{row.cells.map(c => c)}
			</div>))}
		</section>
	</div>;
};